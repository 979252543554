import React from 'react';

interface ArrowForwardProps {
    fill?: string;
    rotate?: boolean;
    width?: string;
    className?: string;
}

export const ArrowForward: React.FC<ArrowForwardProps> = ({ fill = "#FBFBF9", width = '31', rotate = false, className }) => {
    return (
        <svg
            className={className} // Apply className here
            width={width}
            height="100%"
            viewBox="0 0 31 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={rotate ? { transform: "rotateX(180deg)" } : {}}
        >
            <path
                d="M20.7188 16.25H5.5V13.75H20.7188L13.7188 6.75L15.5 5L25.5 15L15.5 25L13.7188 23.25L20.7188 16.25Z"
                fill={fill}
            />
        </svg>
    );
};
