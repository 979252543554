export const WavingHand2 = ({ className = "" }: { className?: string }) => {
    return (
        <svg width="100%" height="760" className={`handsStyle ${className}`} viewBox="0 0 683 760" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2619_58924)">
                <path d="M965.948 500.566C812.38 693.689 613.608 731.765 451.563 654.354C289.124 576.749 196.471 381.195 169.612 236.792C161.088 190.989 161.415 129.326 171.559 123.075C184.979 114.806 197.114 188.915 197.114 188.915C197.114 188.915 171.481 93.6273 192.696 83.5835C213.911 73.5396 231.966 165.272 231.966 165.272C231.966 165.272 204.894 55.4173 229.557 50.0812C250.719 45.4991 269.047 154.702 269.047 154.702C269.047 154.702 247.364 46.1694 270.025 41.9965C288.214 38.6351 304.18 140.243 309.753 160.906C315.325 181.57 315.267 183.326 322.023 186.037C328.779 188.749 369.874 140.01 389.003 153.768C395.59 158.503 364.73 198.438 359.732 235.904C349.054 315.743 451.575 419.355 538.845 434.899C658.644 456.236 819.25 323.986 883.697 89.0052" fill="#FFE5CC"/>
                <g clipPath="url(#clip1_2619_58924)">
                    <path d="M440.386 181.634C446.012 196.973 440.102 212.408 423.935 221.934" stroke="#191717" strokeWidth="6.8902" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M456.332 201.561C458.233 212.847 454.416 218.434 446.463 225.692" stroke="#191717" strokeWidth="6.8902" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M113.342 188.564C113.342 188.564 112.17 204.388 121.035 222.568" stroke="#191717" strokeWidth="6.8902" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M91.1273 190.602C91.1273 190.602 87.756 195.419 88.6761 207.118" stroke="#191717" strokeWidth="6.8902" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M963.747 499.643C810.179 692.766 611.407 730.843 449.362 653.432C286.897 575.832 194.244 380.278 167.384 235.875C158.86 190.072 159.188 128.409 169.332 122.158C182.752 113.889 194.887 187.998 194.887 187.998C194.887 187.998 169.254 92.7103 190.469 82.6665C211.684 72.6226 229.738 164.355 229.738 164.355C229.738 164.355 202.667 54.5003 227.33 49.1642C248.491 44.5821 266.82 153.785 266.82 153.785C266.82 153.785 245.137 45.2524 267.797 41.0795C285.986 37.7181 301.952 139.326 307.525 159.989C313.098 180.653 313.04 182.409 319.796 185.12C326.552 187.832 367.646 139.093 386.775 152.851C393.363 157.586 362.502 197.521 357.505 234.987C346.826 314.826 449.348 418.438 536.618 433.982C656.417 455.319 817.022 323.069 881.47 88.0882" stroke="#191717" strokeWidth="6.8902" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M288.265 222.382C279.63 229.912 275.12 240.898 279.935 253.127" stroke="#191717" strokeWidth="6.8902" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M263.088 176.035C263.088 176.035 246.142 177.022 233.76 182.848C221.556 188.611 215.552 198.124 215.552 198.124" stroke="#191717" strokeWidth="6.8902" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
            </g>
        </svg>
    );
};
