export const FRFlag = ({ width = "100%" }: { width: string }) => {
    return (
        <svg width={width} height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_974_23292)">
                <mask id="mask0_974_23292" style={{maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                    <path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="white"/>
                </mask>
                <g mask="url(#mask0_974_23292)">
                    <path d="M7.82852 0H16.1723L17.3863 11.8266L16.1723 24H7.82852L6.43164 12.1219L7.82852 0Z" fill="#EEEEEE"/>
                    <path d="M0 0H7.82812V24H0V0Z" fill="#0052B4"/>
                    <path d="M16.1719 0H24V24H16.1719V0Z" fill="#D80027"/>
                </g>
            </g>
            <defs>
                <clipPath id="clip0_974_23292">
                    <rect width="24" height="24" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
};
