import { MouseEventHandler } from "react";

export const ArrowCarousel = ({ onClick = () => { return; } }: { onClick?: MouseEventHandler<SVGSVGElement> }) => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" onClick={onClick} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z" fill="#EFEEE1"/>
            <mask id="mask0_2617_71598"  maskUnits="userSpaceOnUse" x="10" y="10" width="20" height="20">
                <rect x="10" y="10" width="20" height="20" fill="#D9D9D9"/>
            </mask>
            <g mask="url(#mask0_2617_71598)">
                <path d="M16.875 20.75L21.0625 24.9375L20 26L14 20L20 14L21.0625 15.0625L16.875 19.25H26V20.75H16.875Z" fill="#191717" />
            </g>
        </svg>
    );
};
