export const WellbeingHand = () => {
    if (window.innerHeight > 1600) {
        return (
            <svg width="268" height="522" viewBox="0 0 268 522" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_3158_61957)">
                    <path d="M198.068 290.835C191.502 304.373 175.082 336.344 171.509 381.25C167.935 426.156 163.653 544.466 165.794 565.131L4.44175 570.835C4.44175 570.835 2.30084 495.286 4.44175 439.694C6.58266 384.103 28.9526 256.52 73.2206 200.214L158.579 195.157L198.068 247.425V290.835Z" fill="#FFE5CC" stroke="#EE8C4B" strokeWidth="4.22428" strokeMiterlimit="10"/>
                    <path d="M127.484 352.743C138.433 353.214 141.763 352.743 146.997 358.919" stroke="#EE8C4B" strokeWidth="4.22428" strokeMiterlimit="10"/>
                    <path d="M222.68 118.496C233.148 114.221 237.911 123.25 240.768 129.897C243.625 136.544 244.578 145.581 237.439 151.756C230.299 157.932 212.211 170.284 212.211 170.284C212.211 170.284 187.936 176.46 184.127 161.736C180.317 147.011 182.222 139.876 189.841 135.602C197.461 131.327 222.68 118.496 222.68 118.496Z" fill="#FFE5CC" stroke="#EE8C4B" strokeWidth="4.22428" strokeMiterlimit="10"/>
                    <path d="M234.573 164.108C244.57 163.157 254.162 161.096 259.8 175.509C263.374 184.639 257.955 196.696 247.486 199.54C237.017 202.384 209.346 206.936 203.639 205.707C197.933 204.479 186.984 198.783 187.456 188.803C187.928 178.824 191.409 175.972 204.213 171.454C217.016 166.935 234.582 164.091 234.582 164.091L234.573 164.108Z" fill="#FFE5CC" stroke="#EE8C4B" strokeWidth="4.22428" strokeMiterlimit="10"/>
                    <path d="M204.112 211.152C198.388 215.611 192.691 220.054 195.067 228.199C197.444 236.343 202.443 243.462 212.557 243.941C222.672 244.421 243.145 238.716 251.237 234.442C259.328 230.167 263.652 224.942 264.588 215.434C265.524 205.927 256.732 199.919 252.51 200.23C242.909 200.937 210.045 206.532 204.12 211.152H204.112Z" fill="#FFE5CC" stroke="#EE8C4B" strokeWidth="4.22428" strokeMiterlimit="10"/>
                    <path d="M144.047 0.657503C134.792 1.17076 105.174 4.14092 96.9723 4.77197C88.7711 5.40302 76.3893 6.35381 70.8431 11.4275C65.297 16.5011 61.4787 19.9761 60.5263 31.0659C59.5738 42.1556 60.206 58.9416 60.5263 65.9084C60.8466 72.8752 67.1935 158.438 68.1459 171.564C69.0984 184.689 73.5403 232.523 74.8131 238.539C76.0858 244.555 78.193 259.448 84.5905 263.563C90.9879 267.677 114.319 271.766 123.043 271.152C131.766 270.538 149.694 270.218 158.899 269.587C168.103 268.956 198.286 265.237 207.946 260.921C217.605 256.604 228.967 248.367 229.633 234.114C230.299 219.86 228.714 197.05 228.082 185.968C227.45 174.887 219.83 93.1531 217.613 75.736C215.397 58.3189 215.784 32.0251 212.573 23.1567C209.362 14.2883 203.967 10.1654 196.036 5.73117C188.104 1.29697 154.246 0.102176 144.047 0.657503Z" fill="#0C5138"/>
                    <path d="M117.327 12.3783C109.708 13.6488 92.8924 15.5419 89.0826 21.5664C85.2728 27.5908 84.3203 36.1395 84.6406 40.5737C84.9609 45.0079 87.498 94.7433 88.7623 108.357C90.0266 121.971 95.0165 182.636 96.9635 199.187C98.9106 215.737 98.2784 242.031 102.72 249.629C107.162 257.227 110.972 258.817 117.319 260.079C123.666 261.341 139.529 261.03 154.448 260.079C169.367 259.129 197.923 256.15 203.63 253.205C209.336 250.26 219.181 240.121 219.493 234.105C219.805 228.089 220.445 197.041 219.493 185.96C218.54 174.879 204.582 43.4177 204.262 36.4508C203.942 29.484 201.885 20.9269 194.501 16.4927C187.118 12.0585 164.276 9.20616 155.072 9.83721C145.867 10.4683 117.311 12.3698 117.311 12.3698L117.327 12.3783Z" fill="#FBFBF9"/>
                    <path d="M102.629 120.877C97.8665 122.779 96.6022 127.213 97.2343 130.377C97.8665 133.54 97.5546 137.983 102.629 138.614C107.703 139.245 115.002 138.294 114.69 134.18C114.378 130.065 114.058 122.139 111.833 120.877C109.608 119.615 102.629 120.877 102.629 120.877Z" fill="#FFCC00"/>
                    <path d="M141.739 155.796C140.71 158.522 142.084 160.752 143.652 161.711C145.22 162.67 146.964 164.504 149.181 162.62C151.398 160.735 153.809 157.319 151.954 155.872C150.1 154.424 146.653 151.513 145.27 151.951C143.888 152.388 141.73 155.804 141.73 155.804L141.739 155.796Z" fill="#FFE5CC"/>
                    <path d="M177.738 105.513C166.132 111.866 157.138 117.099 156.017 129.064C154.896 141.029 154.896 156.729 163.131 160.465C171.374 164.201 191.595 169.342 195.902 163.78C200.209 158.219 211.445 145.665 208.452 135.871C205.46 126.077 201.709 115.98 195.902 111.866C190.095 107.751 177.738 105.513 177.738 105.513Z" fill="#97ADFF"/>
                    <path d="M148.128 111.638C139.834 108.096 133.276 105.471 127.41 109.661C121.535 113.843 114.522 120.111 116.722 125.917C118.922 131.723 126.12 144.369 130.621 144.403C135.122 144.436 146.012 145.311 148.979 139.834C151.946 134.356 154.702 128.365 153.817 123.679C152.932 118.992 148.128 111.63 148.128 111.63V111.638Z" fill="#20CF27"/>
                    <path d="M102.089 157.764C103.994 161.879 110.821 165.328 110.821 165.328C110.821 165.328 117 167.701 122.082 164.79C127.165 161.879 129.087 153.01 128.438 150.478C127.789 147.945 123.212 142.686 119.629 141.677C116.047 140.667 110.341 138.892 107.484 141.677C104.626 144.462 100.816 148.896 100.816 151.748C100.816 154.601 102.089 157.764 102.089 157.764Z" fill="#A48FFA"/>
                    <path d="M110.948 183.991L112.895 200.037C112.895 200.037 142.152 198.161 148.625 197.53C155.098 196.899 204.71 193.491 204.71 193.491L203.016 177.26L137.887 181.694L110.957 183.991H110.948Z" fill="#E1DFC7"/>
                    <path d="M94.4951 60.5486L96.4422 76.6026L150.808 73.1781L149.105 56.939L94.4951 60.5486Z" fill="#C5C4B0"/>
                    <path d="M96.9727 80.2124L98.9113 96.258L177.931 90.4607L176.237 74.23L96.9727 80.2124Z" fill="#C5C4B0"/>
                    <path d="M110.948 183.991L112.895 200.037C112.895 200.037 142.152 198.16 148.625 197.529C155.098 196.898 167.792 195.055 167.792 195.055L166.098 178.825L137.887 181.694L110.957 183.991H110.948Z" fill="#27A577"/>
                    <path d="M192.714 256.628C182.726 260.078 181.964 258.848 174.344 264.082C166.725 269.316 164.98 277.704 166.885 283.88C168.79 290.056 174.403 297.797 185.024 297.797C195.644 297.797 207.503 294.331 217.019 291.478C226.536 288.626 235.099 283.401 237.24 273.901C239.381 264.402 235.706 256.459 232.722 250.401C229.177 243.202 217.019 245.696 217.019 245.696" fill="#FFE5CC" stroke="#EE8C4B" strokeWidth="4.22428" strokeMiterlimit="10"/>
                    <path d="M49.8984 295.73C60.367 279.575 78.9356 251.539 87.9797 241.56C97.0237 231.581 123.726 196.898 134.65 207.828C145.574 218.758 146.046 221.61 140.811 233.962C135.577 246.314 129.862 257.244 131.767 270.546C133.672 283.849 141.283 300.719 141.283 300.719" fill="#FFE5CC"/>
                    <path d="M49.8984 295.73C60.367 279.575 78.9356 251.539 87.9797 241.56C97.0237 231.581 123.726 196.898 134.65 207.828C145.574 218.758 146.046 221.61 140.811 233.962C135.577 246.314 129.862 257.244 131.767 270.546C133.672 283.849 141.283 300.719 141.283 300.719" stroke="#EE8C4B" strokeWidth="4.22428" strokeMiterlimit="10"/>
                </g>
                <defs>
                    <clipPath id="clip0_3158_61957">
                        <rect width="267.066" height="573.35" fill="white" transform="translate(0.539062 0.53125)"/>
                    </clipPath>
                </defs>
            </svg>
        );
    }

    if (window.innerWidth < 1000) {
        return (
            <svg width="98" height="180" viewBox="0 0 98 180" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_3154_39996)">
                    <path d="M72.0041 106.039C69.6111 110.973 63.627 122.625 62.3245 138.991C61.0221 155.357 59.4615 198.476 60.2418 206.007L1.43638 208.086C1.43638 208.086 0.65612 180.552 1.43638 160.291C2.21665 140.03 10.3695 93.5327 26.5031 73.0114L57.6122 71.1685L72.0041 90.2177V106.039Z" fill="#FFE5CC" stroke="#EE8C4B" strokeWidth="1.59177" strokeMiterlimit="10"/>
                    <path d="M46.28 128.602C50.2704 128.773 51.4838 128.602 53.3915 130.852" stroke="#EE8C4B" strokeWidth="1.59177" strokeMiterlimit="10"/>
                    <path d="M80.9741 43.2295C84.7894 41.6717 86.5251 44.9621 87.5664 47.3846C88.6078 49.8072 88.9549 53.1006 86.353 55.3515C83.7511 57.6023 77.1588 62.104 77.1588 62.104C77.1588 62.104 68.3118 64.3548 66.9233 58.9884C65.5348 53.622 66.229 51.0215 69.006 49.4637C71.783 47.9059 80.9741 43.2295 80.9741 43.2295Z" fill="#FFE5CC" stroke="#EE8C4B" strokeWidth="1.59177" strokeMiterlimit="10"/>
                    <path d="M85.3084 59.8527C88.9517 59.5062 92.4475 58.7549 94.5026 64.0078C95.8051 67.335 93.8299 71.7294 90.0146 72.7658C86.1993 73.8023 76.1142 75.4613 74.0345 75.0136C71.9549 74.5659 67.9645 72.4899 68.1365 68.8529C68.3085 65.216 69.5772 64.1765 74.2434 62.5298C78.9097 60.883 85.3115 59.8465 85.3115 59.8465L85.3084 59.8527Z" fill="#FFE5CC" stroke="#EE8C4B" strokeWidth="1.59177" strokeMiterlimit="10"/>
                    <path d="M74.2066 76.9978C72.1208 78.623 70.0442 80.2421 70.9104 83.2105C71.7767 86.1789 73.5983 88.7732 77.2846 88.948C80.9709 89.1228 88.4326 87.0437 91.3816 85.4859C94.3306 83.9281 95.9065 82.0238 96.2475 78.5586C96.5885 75.0934 93.3845 72.9039 91.8454 73.0174C88.3466 73.275 76.3692 75.3142 74.2097 76.9978H74.2066Z" fill="#FFE5CC" stroke="#EE8C4B" strokeWidth="1.59177" strokeMiterlimit="10"/>
                    <path d="M35.1718 1.93983C36.3168 1.85173 38.5953 1.63971 41.1627 1.4008C45.3219 1.01378 50.2394 0.556188 52.325 0.440525C54.1744 0.339836 58.1813 0.3977 62.0883 0.682986C64.0413 0.825588 65.9658 1.02478 67.5809 1.28865C69.2032 1.55372 70.4908 1.88086 71.1865 2.26977C74.0637 3.87833 75.9906 5.35668 77.142 8.5363C77.4278 9.3255 77.6369 10.5144 77.8007 11.9653C77.9642 13.4129 78.0813 15.1085 78.1872 16.9048C78.2278 17.5919 78.2667 18.2939 78.3059 19.0021C78.4716 21.992 78.6435 25.0934 78.9708 27.6652C79.7783 34.0097 82.5549 63.7943 82.7851 67.8288C82.8141 68.3376 82.8486 68.9139 82.8863 69.5428C83.1479 73.9128 83.5619 80.8272 83.3502 85.3591C83.1119 90.4603 79.0456 93.4254 75.5397 94.992C73.8022 95.7684 70.1937 96.4982 66.58 97.0631C62.9737 97.6268 59.3896 98.0225 57.7181 98.1371C56.0441 98.2519 53.5752 98.3384 51.1238 98.4243L51.1208 98.4245C48.6729 98.5102 46.2425 98.5954 44.6499 98.7075C43.0815 98.8179 40.1776 98.5047 37.3655 97.9731C35.9619 97.7078 34.5864 97.3891 33.4181 97.0439C32.2456 96.6974 31.2954 96.3282 30.7326 95.9663C30.1814 95.6117 29.7119 95.0173 29.3111 94.2749C28.9111 93.5341 28.5856 92.6578 28.3166 91.7542C27.9096 90.387 27.6359 88.9743 27.4255 87.8878C27.3573 87.5356 27.2958 87.2177 27.2384 86.9467C27.0089 85.8617 26.4891 80.968 25.9824 75.7312C25.4763 70.5011 24.985 64.9484 24.8116 62.5585C24.739 61.5579 24.5805 59.4042 24.3742 56.6001C23.5933 45.9887 22.127 26.0624 22.0348 24.0562C21.918 21.5156 21.6883 15.4082 22.0344 11.3785C22.2065 9.37501 22.636 8.07337 23.2639 7.04667C23.8939 6.01651 24.7291 5.25245 25.7421 4.32581L25.7436 4.32444C26.7202 3.43097 28.3165 2.88849 30.066 2.54294C31.8098 2.19853 33.6759 2.05494 35.1718 1.93983Z" fill="#0C5138" stroke="#EE8C4B" strokeWidth="0.316496"/>
                    <path d="M42.6044 4.71016L42.6314 4.70566L42.7004 4.70106L43.0365 4.67867L44.2614 4.59702C45.2895 4.52845 46.6953 4.43463 48.2059 4.33363C51.2271 4.13162 54.6677 3.90086 56.3452 3.78585C58.0056 3.67201 60.9124 3.87254 63.7445 4.30495C65.159 4.52093 66.5509 4.79416 67.7552 5.11344C68.9627 5.43355 69.9701 5.79701 70.6232 6.18926C73.2431 7.76265 73.9878 10.8093 74.1039 13.3347C74.1625 14.6103 75.4644 27.231 76.7936 40.026C77.0251 42.2547 77.2575 44.4889 77.4844 46.6698C78.5606 57.0165 79.512 66.1639 79.6552 67.8302C80.0013 71.8572 79.7685 83.1635 79.6548 85.3551C79.6283 85.867 79.3966 86.4835 79.0168 87.1463C78.6386 87.8062 78.1213 88.4986 77.5406 89.159C76.3767 90.4829 74.9749 91.6594 73.959 92.1837C73.4626 92.4399 72.4446 92.7057 71.0885 92.9644C69.7399 93.2216 68.0776 93.4683 66.3085 93.6925C62.7704 94.141 58.8125 94.4987 56.097 94.6717C53.3804 94.8449 50.579 94.9597 48.1687 94.9739C45.7538 94.9881 43.7451 94.9009 42.6062 94.6745C41.4543 94.4454 40.5536 94.1901 39.7413 93.6588C38.9302 93.1283 38.1935 92.3132 37.3914 90.9412C37.0029 90.2766 36.717 89.1844 36.5001 87.804C36.2841 86.4291 36.1393 84.7889 36.0226 83.0421C35.9473 81.9139 35.8837 80.7408 35.8201 79.5675C35.6872 77.115 35.5542 74.6621 35.3138 72.6187C34.8159 68.3865 33.7719 56.2356 33.0341 47.6498C32.7208 44.0034 32.4628 41 32.3253 39.5194C31.9929 35.9401 31.4197 25.5186 31.0819 19.3777C30.9515 17.0055 30.8561 15.272 30.8234 14.8186C30.7082 13.2237 31.0535 10.1453 32.4182 7.9873C32.7412 7.47646 33.3518 7.03773 34.1552 6.66165C34.9551 6.28719 35.9269 5.98358 36.9485 5.73234C38.5209 5.34566 40.1933 5.08698 41.5172 4.88221C41.9152 4.82066 42.2816 4.76398 42.6044 4.71016Z" fill="#FBFBF9" stroke="#EE8C4B" strokeWidth="0.316496"/>
                    <path d="M35.41 47.5281C35.1924 46.4391 35.6248 44.913 37.2646 44.25L37.2766 44.2479C37.295 44.2447 37.3223 44.2401 37.3574 44.2343C37.4276 44.2228 37.529 44.2068 37.6534 44.189C37.9023 44.1534 38.242 44.1107 38.6063 44.0823C38.9712 44.0538 39.3572 44.04 39.6994 44.0609C40.0476 44.0822 40.3277 44.1385 40.4973 44.2347C40.6465 44.3193 40.79 44.5364 40.915 44.8904C41.0369 45.2354 41.1293 45.6751 41.2011 46.1547C41.3164 46.9255 41.3759 47.7789 41.4245 48.4764C41.4364 48.6471 41.4477 48.8086 41.459 48.9572C41.4832 49.2772 41.3626 49.5343 41.1311 49.7459C40.8944 49.9622 40.5413 50.1301 40.1125 50.2493C39.2559 50.4876 38.1505 50.5174 37.2404 50.4042C36.8015 50.3496 36.4909 50.2144 36.2647 50.0337C36.0382 49.8527 35.886 49.6179 35.7777 49.3475C35.6686 49.0754 35.6056 48.7718 35.5555 48.4574C35.5381 48.3484 35.522 48.2363 35.506 48.124C35.4768 47.9203 35.4476 47.7159 35.41 47.5281Z" fill="#FFCC00" stroke="#EE8C4B" strokeWidth="0.316496"/>
                    <path d="M51.6184 56.8905L51.6386 56.8592C51.6606 56.8257 51.6927 56.7773 51.7328 56.7187C51.8131 56.6014 51.9251 56.4437 52.0522 56.2816C52.1797 56.1189 52.3201 55.9547 52.4573 55.8227C52.5987 55.6865 52.7203 55.601 52.8094 55.5728C52.8769 55.5514 53.0029 55.5659 53.1975 55.6492C53.3833 55.7288 53.5991 55.8566 53.8267 56.01C54.1851 56.2517 54.5559 56.5454 54.8646 56.7898C54.9482 56.856 55.0273 56.9187 55.1003 56.9757C55.226 57.0737 55.2802 57.2017 55.2802 57.3611C55.2803 57.5278 55.2201 57.7287 55.1078 57.9489C54.8836 58.3884 54.4775 58.8556 54.0845 59.1897C53.9005 59.3461 53.7355 59.4179 53.5872 59.4411C53.4386 59.4643 53.2936 59.4407 53.1466 59.3853C52.9978 59.3292 52.8514 59.2423 52.7016 59.143C52.6499 59.1088 52.5962 59.0719 52.5418 59.0345C52.4455 58.9684 52.3471 58.9008 52.2544 58.8441C51.7372 58.5277 51.2834 57.7946 51.6184 56.8905Z" fill="#FFE5CC" stroke="#EE8C4B" strokeWidth="0.316496"/>
                    <path d="M56.8359 47.0955C57.234 42.8471 60.4019 40.9738 64.6211 38.6635C64.654 38.6697 64.7013 38.6788 64.7615 38.6908C64.8878 38.7158 65.071 38.7533 65.2974 38.803C65.7505 38.9024 66.3765 39.0505 67.068 39.2452C68.4571 39.6362 70.0889 40.2092 71.1231 40.9419C72.1482 41.6682 73.0047 42.9335 73.7436 44.4792C74.481 46.0218 75.0926 47.8249 75.6373 49.6078C76.1616 51.3241 75.4431 53.3079 74.3628 55.1457C73.5035 56.6075 72.4351 57.9452 71.6364 58.9453C71.4342 59.1984 71.2493 59.43 71.0895 59.6364C70.7393 60.0886 70.0427 60.3463 69.0942 60.4335C68.1541 60.5199 67.0064 60.4352 65.8057 60.2436C63.4044 59.8604 60.8247 59.0554 59.3364 58.381C57.9152 57.7362 57.1754 56.042 56.8525 53.9039C56.5313 51.7775 56.632 49.2721 56.8359 47.0955Z" fill="#97ADFF" stroke="#EE8C4B" strokeWidth="0.316496"/>
                    <path d="M53.6991 40.8574C53.711 40.876 53.7265 40.9003 53.7452 40.9299C53.7933 41.0059 53.8624 41.1166 53.9465 41.2548C54.1147 41.5314 54.3422 41.9178 54.5797 42.357C55.0575 43.2408 55.5648 44.3197 55.7211 45.1473C55.875 45.9621 55.714 46.9043 55.3717 47.9024C55.0302 48.8985 54.5138 49.9341 53.9742 50.9303C53.4739 51.8539 52.2868 52.2666 50.9579 52.4345C49.9081 52.567 48.8168 52.5419 47.9959 52.5231C47.7858 52.5182 47.5934 52.5138 47.4239 52.5126C47.0792 52.51 46.6468 52.2595 46.1612 51.796C45.6835 51.34 45.1839 50.7069 44.7065 50.0023C43.7516 48.5932 42.9013 46.9229 42.5052 45.8776C42.1344 44.899 42.5302 43.8594 43.3256 42.8472C44.1178 41.8391 45.2772 40.8975 46.3441 40.138L46.3443 40.1379C47.3712 39.4044 48.4578 39.2642 49.6696 39.4686C50.879 39.6727 52.2011 40.2179 53.6991 40.8574Z" fill="#20CF27" stroke="#EE8C4B" strokeWidth="0.316496"/>
                    <path d="M37.0244 57.5405C37.7187 59.04 40.2069 60.2973 40.2069 60.2973C40.2069 60.2973 42.4586 61.1621 44.311 60.1011C46.1633 59.04 46.8637 55.8079 46.6272 54.8849C46.3906 53.9619 44.7226 52.0453 43.417 51.6773C42.1115 51.3093 40.0318 50.6623 38.9904 51.6773C37.949 52.6923 36.5605 54.3084 36.5605 55.3479C36.5605 56.3875 37.0244 57.5405 37.0244 57.5405Z" fill="#A48FFA"/>
                    <path d="M40.2529 67.0992L40.9625 72.9471C40.9625 72.9471 51.6251 72.2633 53.9843 72.0333C56.3436 71.8033 74.4248 70.5613 74.4248 70.5613L73.8073 64.646L50.0707 66.2621L40.256 67.0992H40.2529Z" fill="#E1DFC7"/>
                    <path d="M34.2566 22.11L34.9662 27.9609L54.78 26.7128L54.1594 20.7944L34.2566 22.11Z" fill="#C5C4B0"/>
                    <path d="M35.1597 29.2765L35.8662 35.1244L64.6653 33.0115L64.0478 27.0962L35.1597 29.2765Z" fill="#C5C4B0"/>
                    <path d="M40.2529 67.0992L40.9625 72.947C40.9625 72.947 51.6251 72.2632 53.9843 72.0332C56.3436 71.8032 60.9698 71.1317 60.9698 71.1317L60.3524 65.2163L50.0707 66.262L40.256 67.0992H40.2529Z" fill="#27A577"/>
                    <path d="M70.0528 93.572C66.4126 94.8293 66.135 94.3813 63.358 96.2886C60.581 98.196 59.9451 101.253 60.6393 103.504C61.3336 105.755 63.3795 108.576 67.2501 108.576C71.1207 108.576 75.4428 107.313 78.911 106.273C82.3792 105.234 85.5002 103.329 86.2805 99.8673C87.0608 96.4052 85.7214 93.5104 84.634 91.3025C83.3417 88.6788 78.911 89.5879 78.911 89.5879" fill="#FFE5CC" stroke="#EE8C4B" strokeWidth="1.59177" strokeMiterlimit="10"/>
                    <path d="M18.0031 107.823C21.8184 101.935 28.5858 91.7172 31.8819 88.0803C35.1781 84.4434 44.9098 71.8031 48.891 75.7866C52.8722 79.77 53.0442 80.8095 51.1366 85.3112C49.2289 89.8129 47.1462 93.7963 47.8404 98.6445C48.5347 103.493 51.3086 109.641 51.3086 109.641" fill="#FFE5CC"/>
                    <path d="M18.0031 107.823C21.8184 101.935 28.5858 91.7172 31.8819 88.0803C35.1781 84.4434 44.9098 71.8031 48.891 75.7866C52.8722 79.77 53.0442 80.8095 51.1366 85.3112C49.2289 89.8129 47.1462 93.7963 47.8404 98.6445C48.5347 103.493 51.3086 109.641 51.3086 109.641" stroke="#EE8C4B" strokeWidth="1.59177" strokeMiterlimit="10"/>
                </g>
                <defs>
                    <clipPath id="clip0_3154_39996">
                    <rect width="97.3332" height="208.96" fill="white" transform="translate(0.0140381 0.236328)"/>
                    </clipPath>
                </defs>
            </svg>
        );
    }

    return (
        <svg width="293" height="391" viewBox="0 0 293 391" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3154_28207)">
                <path d="M216.556 317.865C209.366 332.689 191.388 367.696 187.474 416.866C183.561 466.036 178.873 595.58 181.217 618.207L4.54333 624.454C4.54333 624.454 2.19913 541.73 4.54333 480.86C6.88754 419.989 31.3817 280.292 79.8533 218.639L173.317 213.102L216.556 270.333V317.865Z" fill="#FFE5CC" stroke="#EE8C4B" strokeWidth="4.62541" strokeMiterlimit="10"/>
                <path d="M139.27 385.652C151.259 386.168 154.904 385.652 160.636 392.414" stroke="#EE8C4B" strokeWidth="4.62541" strokeMiterlimit="10"/>
                <path d="M243.505 129.161C254.967 124.481 260.182 134.366 263.31 141.645C266.439 148.923 267.482 158.818 259.665 165.58C251.848 172.342 232.042 185.867 232.042 185.867C232.042 185.867 205.462 192.629 201.29 176.507C197.119 160.384 199.205 152.571 207.548 147.891C215.891 143.211 243.505 129.161 243.505 129.161Z" fill="#FFE5CC" stroke="#EE8C4B" strokeWidth="4.62541" strokeMiterlimit="10"/>
                <path d="M256.527 179.105C267.472 178.063 277.975 175.806 284.149 191.588C288.063 201.584 282.128 214.787 270.666 217.901C259.203 221.015 228.904 225.999 222.656 224.654C216.407 223.309 204.419 217.071 204.936 206.145C205.452 195.218 209.264 192.095 223.283 187.148C237.302 182.2 256.536 179.086 256.536 179.086L256.527 179.105Z" fill="#FFE5CC" stroke="#EE8C4B" strokeWidth="4.62541" strokeMiterlimit="10"/>
                <path d="M223.172 230.615C216.906 235.498 210.667 240.363 213.269 249.281C215.872 258.199 221.345 265.993 232.42 266.518C243.495 267.043 265.913 260.797 274.773 256.117C283.633 251.437 288.367 245.715 289.392 235.305C290.416 224.894 280.79 218.316 276.166 218.657C265.654 219.431 229.67 225.557 223.182 230.615H223.172Z" fill="#FFE5CC" stroke="#EE8C4B" strokeWidth="4.62541" strokeMiterlimit="10"/>
                <path d="M157.406 0.132917C147.272 0.694912 114.841 3.94711 105.861 4.63809C96.8809 5.32906 83.3233 6.37013 77.2505 11.9256C71.1777 17.481 66.9969 21.286 65.954 33.4288C64.9111 45.5716 65.6033 63.9515 65.954 71.5799C66.3047 79.2083 73.2543 172.896 74.2972 187.268C75.3401 201.64 80.2038 254.016 81.5974 260.604C82.991 267.191 85.2983 283.498 92.3033 288.003C99.3082 292.508 124.855 296.986 134.407 296.313C143.959 295.641 163.589 295.291 173.668 294.6C183.746 293.909 216.795 289.836 227.372 285.11C237.949 280.384 250.39 271.364 251.119 255.757C251.848 240.151 250.113 215.174 249.42 203.041C248.728 190.907 240.385 101.412 237.958 82.3407C235.531 63.2698 235.955 34.4791 232.439 24.7685C228.922 15.058 223.016 10.5436 214.331 5.68837C205.647 0.833107 168.573 -0.475142 157.406 0.132917Z" fill="#0C5138"/>
                <path d="M128.15 12.9667C119.807 14.3579 101.394 16.4308 97.2228 23.0273C93.0512 29.6238 92.0083 38.9843 92.359 43.8395C92.7097 48.6948 95.4877 103.153 96.872 118.06C98.2564 132.966 103.72 199.392 105.852 217.514C107.984 235.636 107.292 264.427 112.156 272.746C117.019 281.066 121.191 282.807 128.14 284.189C135.09 285.571 152.459 285.23 168.795 284.189C185.13 283.148 216.399 279.886 222.647 276.662C228.895 273.437 239.675 262.336 240.016 255.748C240.358 249.161 241.059 215.165 240.016 203.031C238.973 190.898 223.69 46.9535 223.339 39.3252C222.988 31.6968 220.737 22.3271 212.652 17.4719C204.567 12.6166 179.556 9.49338 169.478 10.1844C159.4 10.8753 128.131 12.9575 128.131 12.9575L128.15 12.9667Z" fill="#FBFBF9"/>
                <path d="M112.054 131.768C106.839 133.851 105.455 138.706 106.147 142.17C106.839 145.634 106.498 150.499 112.054 151.19C117.61 151.881 125.602 150.839 125.261 146.334C124.919 141.829 124.569 133.15 122.132 131.768C119.696 130.387 112.054 131.768 112.054 131.768Z" fill="#FFCC00"/>
                <path d="M154.877 170.003C153.751 172.988 155.255 175.429 156.972 176.479C158.689 177.53 160.599 179.538 163.026 177.474C165.454 175.411 168.093 171.67 166.063 170.085C164.032 168.501 160.258 165.313 158.744 165.792C157.23 166.271 154.868 170.012 154.868 170.012L154.877 170.003Z" fill="#FFE5CC"/>
                <path d="M194.294 114.946C181.586 121.902 171.738 127.632 170.511 140.733C169.283 153.834 169.283 171.025 178.3 175.116C187.326 179.207 209.467 184.836 214.183 178.746C218.899 172.656 231.202 158.91 227.926 148.186C224.649 137.462 220.542 126.407 214.183 121.902C207.824 117.396 194.294 114.946 194.294 114.946Z" fill="#97ADFF"/>
                <path d="M161.873 121.652C152.792 117.774 145.611 114.899 139.188 119.487C132.755 124.066 125.076 130.93 127.485 137.287C129.894 143.644 137.776 157.491 142.704 157.528C147.632 157.565 159.556 158.523 162.805 152.525C166.054 146.527 169.072 139.968 168.103 134.836C167.134 129.704 161.873 121.643 161.873 121.643V121.652Z" fill="#20CF27"/>
                <path d="M111.463 172.158C113.549 176.664 121.024 180.441 121.024 180.441C121.024 180.441 127.789 183.039 133.355 179.851C138.92 176.664 141.024 166.953 140.313 164.18C139.603 161.407 134.591 155.649 130.669 154.543C126.746 153.437 120.498 151.494 117.37 154.543C114.241 157.593 110.069 162.448 110.069 165.571C110.069 168.694 111.463 172.158 111.463 172.158Z" fill="#A48FFA"/>
                <path d="M121.163 200.876L123.295 218.445C123.295 218.445 155.329 216.391 162.417 215.7C169.505 215.009 223.828 211.277 223.828 211.277L221.973 193.505L150.659 198.361L121.172 200.876H121.163Z" fill="#E1DFC7"/>
                <path d="M103.148 65.7112L105.28 83.2896L164.808 79.5399L162.944 61.7588L103.148 65.7112Z" fill="#C5C4B0"/>
                <path d="M105.861 87.2424L107.984 104.812L194.507 98.4638L192.652 80.6919L105.861 87.2424Z" fill="#C5C4B0"/>
                <path d="M121.163 200.876L123.295 218.445C123.295 218.445 155.329 216.39 162.417 215.699C169.505 215.008 183.405 212.991 183.405 212.991L181.549 195.219L150.659 198.36L121.172 200.876H121.163Z" fill="#27A577"/>
                <path d="M210.693 280.41C199.756 284.187 198.922 282.841 190.579 288.572C182.236 294.302 180.326 303.488 182.411 310.25C184.497 317.012 190.644 325.488 202.273 325.488C213.901 325.488 226.887 321.693 237.306 318.569C247.726 315.446 257.103 309.725 259.447 299.323C261.791 288.922 257.768 280.225 254.5 273.591C250.618 265.709 237.306 268.44 237.306 268.44" fill="#FFE5CC" stroke="#EE8C4B" strokeWidth="4.62541" strokeMiterlimit="10"/>
                <path d="M54.3159 323.225C65.7785 305.536 86.1104 274.838 96.0132 263.911C105.916 252.985 135.154 215.009 147.115 226.976C159.076 238.944 159.593 242.067 153.862 255.592C148.13 269.117 141.873 281.084 143.959 295.65C146.045 310.216 154.378 328.688 154.378 328.688" fill="#FFE5CC"/>
                <path d="M54.3159 323.225C65.7785 305.536 86.1104 274.838 96.0132 263.911C105.916 252.985 135.154 215.009 147.115 226.976C159.076 238.944 159.593 242.067 153.862 255.592C148.13 269.117 141.873 281.084 143.959 295.65C146.045 310.216 154.378 328.688 154.378 328.688" stroke="#EE8C4B" strokeWidth="4.62541" strokeMiterlimit="10"/>
            </g>
            <defs>
                <clipPath id="clip0_3154_28207">
                    <rect width="292.426" height="627.794" fill="white" transform="translate(0.27002 -0.00537109)"/>
                </clipPath>
            </defs>
        </svg>
    );
};
