import React, { ChangeEvent } from 'react';

import styles from './styles.module.css';
import { Arrow } from '../../assets/svg/Arrow';

type InputProps = {
    required?: boolean;
    id: string;
    name: string;
    type: string;
    label: string;
    value: string;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void | Function;
    min?: number;
    max?: number;
}

const Input: React.FC<InputProps> = ({ required = false, id, name, type, label, value, onChange, min = 0, max = 9999 }) => {
    const handleArrow = (type: string) => {
        if (type === 'up') {
            onChange({ target: { value: (parseInt(value === '' ? '0' : value) + 1).toString(), name: name }} as ChangeEvent<HTMLInputElement>);
        } else {
            onChange({ target: { value: (parseInt(value === '' ? '0' : value) - 1).toString(), name: name }} as ChangeEvent<HTMLInputElement>);
        }
    };

    return (
        <div className={styles.container}>
            <label className={styles.label}>{label}</label>
            { type === 'number' && <div className={styles.arrows}>
                <Arrow rotate={true} onClick={() => { handleArrow('up'); }} />
                <Arrow onClick={() => { handleArrow('down'); }} />
            </div> }
            <input id={id} name={name} className={styles.input} type={type} value={value} onChange={onChange} required={required} placeholder={(min && max) ? `${min} - ${max}` : ''} />
            {(value && (parseInt(value) < min || parseInt(value) > max)) && <label className={styles.labelError} >{`${min} - ${max}`}</label>}
        </div>
    );
};

export default Input;
