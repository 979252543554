export const HandsThumbsUp = ({ width = "842px" }: { width?: string,  }) => {
    return (
        <svg width="100%" height="760" className="handsStyle" viewBox="0 0 683 760" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2619_59920)">
                <path d="M645.102 714.691C478.81 741.182 309.334 569.682 294.953 483.909L295.029 483.894L294.486 480.588C290.703 482.306 286.71 483.664 282.75 484.89C269.554 488.983 254.285 491.758 242.742 484.224C231.62 476.97 227.819 462.716 224.991 449.752L231.365 449.637C226.452 448.302 221.583 446.517 217.397 443.665C201.921 433.179 199.456 411.79 198.437 393.101L202.205 393.218C200.38 392.039 198.691 390.689 197.31 389.027C194.759 385.915 193.496 381.955 192.286 378.064C190.061 370.872 187.83 363.445 188.286 355.875C188.749 348.343 192.46 340.516 199.334 337.293L209.374 338.448C204.911 335.555 200.846 332.117 197.36 328.004C192.486 322.325 188.998 315.503 187.16 308.273C185.897 303.33 185.428 298.031 187.103 293.209C190.481 283.52 201.315 278.639 211.406 276.544L247.684 267.604C272.546 263.385 273.798 246.996 274.575 226.647C277.063 162.46 295.96 121.093 315.332 117.971C315.789 117.898 316.404 117.759 317.14 117.592C322.68 116.34 335.036 113.547 337.519 125.977C351.524 197.064 481.448 466.332 602.387 442.17C753.418 411.997 783.513 251.719 783.513 251.719L940.404 473.914C940.404 473.914 893.995 675.082 645.102 714.691Z" fill="#FFE5CC"/>
                <path d="M198.437 393.101L198.399 393.1L198.437 393.092L198.437 393.101Z" fill="#FFE5CC"/>
                <path d="M224.991 449.752C224.988 449.737 224.985 449.722 224.982 449.707L224.951 449.753L224.991 449.752Z" fill="#FFE5CC"/>
                <g clipPath="url(#clip1_2619_59920)">
                    <path d="M783.63 252.239C783.63 252.239 753.543 412.517 602.507 442.691C481.57 466.853 351.643 197.573 337.637 126.48C334.855 112.389 319.326 117.861 315.433 118.474C296.044 121.591 277.16 162.984 274.672 227.137C273.895 247.468 272.632 263.881 247.768 268.091L211.471 277.022C201.387 279.102 190.549 283.999 187.154 293.696C185.482 298.506 185.95 303.811 187.202 308.761C189.042 315.996 192.541 322.801 197.395 328.48C209.881 343.2 229.724 349.274 248.738 352.684C268.98 356.309 289.615 357.781 310.162 357.001C319.998 356.648 329.86 355.764 339.292 353.057C345.211 351.381 351.478 348.483 353.935 342.825C355.063 340.23 355.266 337.293 355.096 334.463C354.642 325.93 355.51 320.557 345.637 311.174C335.796 301.784 281.083 291.025 263.113 289.876" stroke="#191717" strokeWidth="6.8902" strokeMiterlimit="10"/>
                    <path d="M199.426 337.815C192.563 341.03 188.827 348.852 188.363 356.416C187.9 363.981 190.139 371.4 192.372 378.622C193.574 382.496 194.826 386.459 197.389 389.568C199.775 392.448 203.091 394.386 206.395 396.096C241.085 414.213 283.925 415.791 319.869 400.316C324.687 398.234 329.441 395.836 333.392 392.381C343.73 383.338 346.968 368.406 346.008 354.708" stroke="#191717" strokeWidth="6.8902" strokeMiterlimit="10"/>
                    <path d="M198.559 393.615C199.593 412.301 202.057 433.696 217.519 444.201C222.089 447.303 227.424 449.134 232.804 450.527C263.034 458.509 296.222 454.61 323.785 439.855C326.343 438.488 328.875 436.995 331.015 435.021C340.847 425.914 338.869 409.589 332.593 397.776" stroke="#191717" strokeWidth="6.8902" strokeMiterlimit="10"/>
                    <path d="M225.082 450.258C227.902 463.222 231.71 477.503 242.836 484.759C254.38 492.294 269.654 489.506 282.859 485.419C291.443 482.75 300.204 479.486 306.656 473.227C313.107 466.968 316.692 456.904 313.041 448.681" stroke="#191717" strokeWidth="6.8902" strokeMiterlimit="10"/>
                    <path d="M295.07 484.429C309.44 570.195 478.911 741.687 645.233 715.206C894.143 675.582 940.519 474.429 940.519 474.429" stroke="#191717" strokeWidth="6.8902" strokeMiterlimit="10"/>
                </g>
            </g>
        </svg>
    );
};