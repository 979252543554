export const HandsHiFive = ({ width = "100%", height="100%", className="" }: { fill?: string, width?: string|number, height?:string, className?:string }) => {
    return (
        <svg className={`handsStyle ${className}`} width={width} height={height} viewBox="0 0 1024 924" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1083.72 555.47C1024.86 622.143 904.318 627.076 834.874 601.022C743.026 566.554 683.517 366.198 744.006 291.415C775.771 252.114 788.646 236.502 778.069 224.742C757.726 202.169 732.246 221.971 732.246 221.971C742.553 158.07 788.646 99.372 764.349 89.9776C758.638 87.7811 749.311 88.7611 749.311 88.7611C749.311 88.7611 758.199 69.8711 741.167 61.8284C738.734 60.6795 736.132 60.3754 733.429 60.7471C733.429 60.5105 736.098 35.031 718.188 34.9972C705.415 34.9634 691.898 44.4253 678.753 58.7195C676.725 56.3203 672.062 52.5693 662.633 52.5355C637.728 52.4679 590.25 118.87 546.826 213.118C546.826 213.253 546.86 213.422 546.894 213.557C533.782 134.009 508.235 72.2703 482.688 95.6886C482.688 95.6886 466.299 58.0099 449.369 66.2891C447.51 67.2015 445.989 68.5194 444.739 70.1752C441.765 64.0925 430.817 44.0874 416.015 47.568C387.123 54.3265 396.821 82.4419 397.801 85.0777C396.956 82.915 389.623 66.0187 370.835 76.4268C347.045 89.6059 376.647 166.721 375.904 250.526L375.431 251.236C361.576 228.122 334.88 218.389 320.856 226.804C314.807 230.419 331.095 261.813 336.164 288.036C349.546 357.243 324.573 428.984 269.119 472.475C267.362 473.861 265.605 475.213 263.78 476.531C173.52 543.237 88.4305 551.009 -17.6443 408.912L-76.1392 814.726C157.367 966.32 338.36 849.6 421.118 768.768C469.813 721.256 502.288 645.291 523.07 565.033C539.291 635.153 566.865 701.555 607.112 749.878C681.186 838.753 849.27 973.382 1097.07 846.525L1083.72 555.436V555.47Z" fill="#FFE5CC"/>
            <path d="M732.279 222.005C742.586 158.103 788.679 99.4053 764.382 90.011C720.689 73.1485 681.185 208.488 681.185 208.488" stroke="#191717" strokeWidth="10.1378" strokeMiterlimit="10"/>
            <path d="M641.343 195.276C641.343 195.276 706.394 45.4401 741.2 61.8632C758.232 69.9059 749.344 88.7959 749.344 88.7959" stroke="#191717" strokeWidth="10.1378" strokeMiterlimit="10"/>
            <path d="M602.213 190.916C602.213 190.916 666.486 34.862 718.223 34.9972C736.234 35.031 733.463 60.7809 733.463 60.7809" stroke="#191717" strokeWidth="10.1378" strokeMiterlimit="10"/>
            <path d="M499.753 242.753C491.001 154.589 471.3 108.158 480.458 97.9524C511.716 63.0785 543.549 158.745 553.078 261.475C562.608 364.238 540.642 652.15 421.118 768.802C338.326 849.634 157.334 966.353 -76.1392 814.76" stroke="#191717" strokeWidth="10.1378" strokeMiterlimit="10"/>
            <path d="M483.094 93.1538C483.094 93.1538 466.333 58.0434 449.403 66.3564C422.606 79.4679 459.608 172.803 458.899 263.908" stroke="#191717" strokeWidth="10.1378" strokeMiterlimit="10"/>
            <path d="M445.618 71.9666C445.618 71.9666 433.486 43.5133 416.083 47.6022C375.769 57.0303 422.47 154.184 421.76 279.419" stroke="#191717" strokeWidth="10.1378" strokeMiterlimit="10"/>
            <path d="M375.464 251.269C361.609 228.155 334.913 218.423 320.889 226.837C314.84 230.453 331.128 261.846 336.197 288.069C349.578 357.276 324.606 429.018 269.152 472.509C267.395 473.894 265.638 475.246 263.813 476.564C173.553 543.271 88.4635 551.043 -17.6113 408.945" stroke="#191717" strokeWidth="10.1378" strokeMiterlimit="10"/>
            <path d="M397.97 85.3472C397.97 85.3472 390.941 65.3758 370.902 76.4935C342.787 92.0719 389.319 197.065 372.017 296.855" stroke="#191717" strokeWidth="10.1378" strokeMiterlimit="10"/>
            <path d="M1083.76 555.502C1024.89 622.175 904.352 627.109 834.908 601.055C743.06 566.586 683.551 366.23 744.04 291.413C775.805 252.113 788.68 236.501 778.103 224.741C757.76 202.167 689.735 240.116 662.634 284.723" stroke="#191717" strokeWidth="10.1378" strokeMiterlimit="10"/>
            <path d="M680.172 60.7472C680.172 60.7472 676.624 52.6032 662.701 52.5695C637.796 52.4681 590.317 118.904 546.894 213.152C549.462 228.73 551.591 245.018 553.112 261.475C558.755 322.369 553.348 448.314 523.104 565.067C539.324 635.187 566.899 701.589 607.146 749.912C681.219 838.787 849.303 973.416 1097.1 846.559" stroke="#191717" strokeWidth="10.1378" strokeMiterlimit="10"/>
            <path d="M248.269 163.409L214.477 158.34M271.924 112.72L248.269 94.1339M256.717 232.683L227.994 249.58M861.604 163.409L907.224 158.34M851.466 100.892L880.19 83.9961M858.225 230.994L880.19 244.511" stroke="#191717" strokeWidth="10.1378" strokeLinecap="round"/>
        </svg>
    );
};
