export const WavingHand = ({ className = "" }: { className?: string }) => {
    return (
        <svg width="100%" height="760" className={`handsStyle ${className}`} viewBox="0 0 683 760" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2619_57835)">
                <path d="M841.213 242.41C841.213 242.41 729.502 372.917 642.683 383.401C581.717 390.776 508.383 361.892 445.143 284.738C404.195 234.778 445.648 163.455 435.833 153.434C407.659 124.665 375.417 212.326 369.327 215.725C360.645 220.557 240.738 77.4565 217.855 113.486C204.667 134.236 291.334 225.426 291.334 225.426C257.509 196.711 175.63 122.838 164.232 157.458C156.877 179.699 226.988 246.122 260.583 271.943C260.583 271.943 177.435 209.294 168.787 223.636C153.036 249.768 228.582 305.399 248.302 330.166C223.533 307.514 201.512 307.645 196.918 314.096C189.432 324.602 214.985 350.385 260.702 402.523C316.044 465.636 394.724 558.485 444.835 600.639C691.323 808.145 975.842 504.954 975.842 504.954" fill="#FFE5CC"/>
                <g clipPath="url(#clip1_2619_57835)">
                    <path d="M841.213 242.41C841.213 242.41 729.502 372.917 642.683 383.401C581.717 390.776 508.383 361.892 445.143 284.738C404.195 234.778 445.648 163.455 435.833 153.434C407.659 124.665 375.417 212.326 369.327 215.725C360.645 220.557 240.738 77.4565 217.855 113.486C204.667 134.236 291.334 225.426 291.334 225.426C257.509 196.711 175.63 122.838 164.232 157.458C156.877 179.699 226.988 246.122 260.583 271.943C260.583 271.943 177.435 209.294 168.787 223.636C153.036 249.768 228.582 305.399 248.302 330.166C223.533 307.514 201.512 307.645 196.918 314.096C189.432 324.602 214.985 350.385 260.702 402.523C316.044 465.636 394.724 558.485 444.835 600.639C691.323 808.145 975.842 504.954 975.842 504.954" stroke="#191717" strokeWidth="6.8902" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M499.701 124.329C513.42 137.427 515.142 156.495 503.189 174.723" stroke="#191717" strokeWidth="6.8902" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M526.474 136.907C534.227 147.689 533.087 155.441 528.551 167.096" stroke="#191717" strokeWidth="6.8902" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M102.107 353.304C102.107 353.304 111.756 368.986 132.411 380.066" stroke="#191717" strokeWidth="6.8902" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M82.5707 370.295C82.5707 370.295 82.672 377.136 91.4771 387.485" stroke="#191717" strokeWidth="6.8902" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
            </g>
        </svg>
    );
};
