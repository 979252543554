import { MouseEventHandler } from "react";

export const Clock = ({ width = '100%', fill = "#191717", onClick = () => {} }: { fill?: string, width?: string, onClick?: MouseEventHandler<SVGSVGElement> }) => {
    return (
        <svg width={width} height="100%" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
            <path d="M7.60299 18.7362L6.33423 17.4675L11.0361 12.7656L6.33423 8.06369L7.60299 6.79492L12.3049 11.4968L17.0068 6.79492L18.2755 8.06369L13.5736 12.7656L18.2755 17.4675L17.0068 18.7362L12.3049 14.0343L7.60299 18.7362Z"
                fill={fill} />
        </svg>
    );
};
