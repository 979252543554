import { FC } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Proceed, Profile, Results, Welcome } from '../Pages';
import { useTranslation } from 'react-i18next';
import './styles.general.css';
import { Intro } from '../Pages/Intro';

export const App: FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { i18n } = useTranslation();

  return (
    <Router>
      <Switch>
        <Route path='/results'>
          <Results />
        </Route>
        <Route path='/proceed'>
          <Proceed />
        </Route>
        <Route path='/profile'>
          <Profile />
        </Route>
        <Route path='/intro'>
          <Intro />
        </Route>
        <Route path='/'>
          <Welcome />
        </Route>
      </Switch>
    </Router>
  );
};
