export const ArrowBack = ({ width = '24px', height = '24px' }: { width?: string|number, height?: string|number }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 25" fill="none">
        <mask id="mask0_2804_9707" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="25">
            <rect y="0.426758" width="24" height="24" fill="#D9D9D9"/>
        </mask>
            <g mask="url(#mask0_2804_9707)">
                <path d="M7.825 13.4268L13.425 19.0268L12 20.4268L4 12.4268L12 4.42676L13.425 5.82676L7.825 11.4268H20V13.4268H7.825Z" fill="#191717"/>
            </g>
</svg>

    );
};
