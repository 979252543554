export const Battery = ({
    score = 0,
    width = "100%",
    height = "100%",
    fill = "#F1F1E9",
}: {
    score?: number,
    width?: string|number,
    height?: string|number
    fill?: string,
}) => {
    let batteryLevel: string[] = [];

    switch (score) {
        case 1:
            batteryLevel = [fill, '#F1F1E9', '#F1F1E9', '#F1F1E9', '#F1F1E9', '#F1F1E9', '#F1F1E9', '#F1F1E9', '#F1F1E9', '#F1F1E9'];
            break;

        case 2:
            batteryLevel = [fill, fill, '#F1F1E9', '#F1F1E9', '#F1F1E9', '#F1F1E9', '#F1F1E9', '#F1F1E9', '#F1F1E9', '#F1F1E9'];
            break;

        case 3:
            batteryLevel = [fill, fill, fill, '#F1F1E9', '#F1F1E9', '#F1F1E9', '#F1F1E9', '#F1F1E9', '#F1F1E9', '#F1F1E9'];
            break;

        case 4:
            batteryLevel = [fill, fill, fill, fill, '#F1F1E9', '#F1F1E9', '#F1F1E9', '#F1F1E9', '#F1F1E9', '#F1F1E9'];
            break;

        case 5:
            batteryLevel = [fill, fill, fill, fill, fill, '#F1F1E9', '#F1F1E9', '#F1F1E9', '#F1F1E9', '#F1F1E9'];
            break;

        case 6:
            batteryLevel = [fill, fill, fill, fill, fill, fill, '#F1F1E9', '#F1F1E9', '#F1F1E9', '#F1F1E9'];
            break;

        case 7:
            batteryLevel = [fill, fill, fill, fill, fill, fill, fill, '#F1F1E9', '#F1F1E9', '#F1F1E9'];
            break;

        case 8:
            batteryLevel = [fill, fill, fill, fill, fill, fill, fill, fill, '#F1F1E9', '#F1F1E9'];
            break;

        case 9:
            batteryLevel = [fill, fill, fill, fill, fill, fill, fill, fill, fill, '#F1F1E9'];
            break;

        case 10:
            batteryLevel = [fill, fill, fill, fill, fill, fill, fill, fill, fill, fill];
            break;

        default:
            break;
    }

    return (
        <svg width={width} height={height} viewBox="0 0 385 103" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M374.375 30H376.875C380.327 30 383.125 32.7982 383.125 36.25V66.25C383.125 69.7018 380.327 72.5 376.875 72.5H374.375V30Z" fill="#EFEEE1" stroke="#EFEEE1" strokeWidth="2.5" />
            <rect x="371.25" y="1.875" width="98.75" height="369.375" rx="9.375" transform="rotate(90 371.25 1.875)" stroke="#EFEEE1" strokeWidth="3.75"/>
            {batteryLevel.map((item, index) => {
                return (
                    <rect key={index} x={41.25 + 35.625 * index} y="11.25" width="80" height="30" rx="3.75" transform={`rotate(90 ${41.25 + 35.625 * index} 11.25)`} fill={item} />
                );
            })}
        </svg>
    );
};
