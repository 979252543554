export const OkIcon = ({ width = '20', height = '20' }: { width?: string|number, height?: string|number}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <mask id="mask0_2616_59349"  maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
                <rect width="20" height="20" fill="#D9D9D9"/>
            </mask>
            <g mask="url(#mask0_2616_59349)">
                <path d="M9.12516 12.9587L13.8335 8.25033L12.646 7.06283L9.12516 10.5837L7.37516 8.83366L6.18766 10.0212L9.12516 12.9587ZM10.0002 18.3337C8.06961 17.8475 6.47586 16.7399 5.21891 15.0107C3.96197 13.2816 3.3335 11.3614 3.3335 9.25033V4.16699L10.0002 1.66699L16.6668 4.16699V9.25033C16.6668 11.3614 16.0384 13.2816 14.7814 15.0107C13.5245 16.7399 11.9307 17.8475 10.0002 18.3337ZM10.0002 16.5837C11.4446 16.1253 12.6391 15.2087 13.5835 13.8337C14.5279 12.4587 15.0002 10.9309 15.0002 9.25033V5.31283L10.0002 3.43783L5.00016 5.31283V9.25033C5.00016 10.9309 5.47238 12.4587 6.41683 13.8337C7.36127 15.2087 8.55572 16.1253 10.0002 16.5837Z" fill="#FBFBF9"/>
            </g>
        </svg>
    );
};