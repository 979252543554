import { FC, LegacyRef } from "react";

import styles from "./styles.module.css";
import Navbar from "../../components/Navbar";
import { ArrowForward, Battery, UserGuard, WellbeingHand } from "../../assets/svg";
import { Button } from "../../components/Button";
import { t } from 'i18next';
import { Trans } from "react-i18next";

const ExportResults: FC<{
    isDetailedResults: boolean,
    resultsData: any,
    exportRef: LegacyRef<HTMLDivElement> | undefined,
}> = ({ isDetailedResults, resultsData, exportRef }) => {
    return (
        <div ref={exportRef} className={styles.result_screen_export}>
            <Navbar isResults={false} onlyLogo={true} />
            <div className={styles.results} style={{ minHeight: 'auto', marginTop: '40px' }}>
                <div style={isDetailedResults ? { display: 'none' } : { display: 'flex', width: '100%' }}>
                    <div className={styles.top_container}>
                        <div className={styles.results_header}>
                            <h2 className={styles.title}>{t('results.header.energy.title')}</h2>
                            <div className={styles.paragraphs_container}>
                                <p className={`${styles.paragraph} ${styles.results_header_size}`}><Trans i18nKey="results.header.energy.description" /></p>
                            </div>
                        </div>
                        <div className={styles.results_info}>
                            <div className={styles.results_info_item}>
                                <div className={styles.results_info_item_details}>
                                    <h3 className={styles.title_item}>{t('results.metabolic.title')}</h3>
                                </div>
                                <div className={styles.results_info_item_battery}>
                                    <Battery score={Math.round((resultsData.PHYSIO_SCORE + resultsData.VITAL_SCORE) / 2) * 2} fill="#18A81D" />
                                    <p className={styles.paragraph_small}>{t('results.metabolic.description')}</p>
                                </div>
                            </div>
                            <div className={styles.results_info_item}>
                                <div className={styles.results_info_item_details}>
                                    <h3 className={styles.title_item}>{t('results.physical.title')}</h3>
                                </div>
                                <div className={styles.results_info_item_battery}>
                                    <Battery score={Math.round(resultsData.PHYSICAL_SCORE) * 2} fill="#D46114" />
                                    <p className={styles.paragraph_small}>{t('results.physical.description')}</p>
                                </div>
                            </div>
                            <div className={styles.results_info_item}>
                                <div className={styles.results_info_item_details}>
                                    <h3 className={styles.title_item}>{t('results.mental.title')}</h3>
                                </div>
                                <div className={styles.results_info_item_battery}>
                                    <Battery score={Math.round(resultsData.MENTAL_SCORE) * 2} fill="#2193C5" />
                                    <p className={styles.paragraph_small}>{t('results.mental.description')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.results_footer} style={{ marginTop: '64px' }}>
                <div className={styles.results_footer_container}>
                    <div className={`${styles.paragraphs_container} ${styles.w_60}`}>
                        <h2 className={styles.bottom_title} style={{ color: '#FBFBF9' }}>{t('results.footer.title')}</h2>
                        <p className={styles.paragraph} style={{ color: '#F1F1E9' }}>{t('results.footer.description')}</p>
                        <Button className={styles.button_container} text={t('results.footer.button')} icon={<ArrowForward fill="#191717" width='20' />} />
                        <div className={`${styles.header_info} ${styles.results_header_size}`}>
                            <div className='d-flex'>
                                <UserGuard width='34px' />
                            </div>
                            <p className={`${styles.info_text} fs-italic`}>{t('results.header.energy.info')}</p>
                        </div>
                    </div>
                    <div className={styles.results_footer_icon}>
                        <WellbeingHand />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ExportResults;
