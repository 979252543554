import React from 'react';

export const MenuIcon: React.FC<{ className?: string; onClick?: () => void, fill?: string }> = ({ className, onClick, fill = '#2A2626' }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            onClick={onClick} // Add the onClick handler
        >
            <path
                d="M21.5 6.92676H2.5V5.92676H21.5V6.92676ZM21.5 12.9268H2.5V11.9268H21.5V12.9268ZM21.5 18.9268H2.5V17.9268H21.5V18.9268Z"
                fill={fill}
                stroke={fill}
            />
        </svg>
    );
};
