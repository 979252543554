import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ArrowCarousel } from "../../assets/svg";
import { t } from "i18next";
import styles from './styles.module.css'
import Navbar from "../../components/Navbar";
import { Button } from "../../components/Button";
import WelcomeFace from '../../assets/images/welcome-face.png';
import Footer from "../../components/Footer";
import { WavingHand } from "../../assets/svg/WavingHand";
import { WavingHand2 } from "../../assets/svg/WavingHand2";
import { HandsHiFive } from "../../assets/svg/HandsHiFive";
import { HandsHeart } from "../../assets/svg/HandsHeart";
import { HandsThumbsUp } from "../../assets/svg/HandThumbsUp";

const StepContent = ({
    step,
    handleNext,
    handleBack,
    image,
    title,
    text,
    buttonText,
    linkTo,
    titleClassName,
    textClassName,
    imageContainerClassName,
    navbarBGColor,
}: {
    step: number;
    handleNext: () => void;
    handleBack?: () => void;
    image?: React.ReactNode;
    title: string;
    text: string;
    buttonText: string;
    linkTo?: string;
    titleClassName: string;
    textClassName: string;
    imageContainerClassName?: string;
    navbarBGColor?: string;
}): JSX.Element => (
    <div className={`${styles.step} ${styles[`step--${step}`]}`}>
        <Navbar isResults={false} backgroundColor={navbarBGColor} />
        <div className={styles.allContentContainer}>
            <div className={styles.headerContainer}>
                <div className={styles.goBack}>
                    {handleBack ? (
                        <ArrowCarousel onClick={handleBack} />
                    ) : (
                        <Link to="/" style={{ textDecoration: 'none' }}>
                            <ArrowCarousel />
                        </Link>
                    )}
                </div>
            </div>
            <div className={styles.allContent}>
                <div className={`${styles.imageContainer} ${imageContainerClassName}`}>
                    {image}
                </div>
                <div className={styles.container}>
                    <p className={`${styles.title} ${titleClassName}`}>{title}</p>
                    <p className={`${styles.text} ${textClassName}`}>{text}</p>
                    {linkTo ? (
                        <Link to={linkTo} style={{ textDecoration: "none", width: '100%' }}>
                            <Button text={buttonText} buttonType="big" onClick={handleNext} className={styles.nextButton} />
                        </Link>
                    ) : (
                        <Button text={buttonText} buttonType="big" onClick={handleNext} className={styles.nextButton} />
                    )}
                </div>
            </div>
        </div>
    </div>
);

export const Intro: React.FC = () => {
    const [currentStep, setCurrentStep] = useState(1);
    const [currentColor, setCurrentColor] = useState('#EFECFF');
    const [step4Image, setStep4Image] = useState<React.ReactNode>(<HandsHeart />);
    const [step3Image, setStep3Image] = useState<React.ReactNode>(<HandsHiFive />);
    const [step2Image, setStep2Image] = useState<React.ReactNode>(
        <img className={`${styles.welcomeFace} ${styles.step2Image}`} src={WelcomeFace} alt="Smile" />
    );

    const handleBack = () => {
        setCurrentStep(prevStep => {
            if (prevStep > 1) {
                const newStep = prevStep - 1;
                setCurrentColor(getBackgroundColor(newStep));
                return newStep;
            }
            return prevStep;
        });
    };

    const handleNext = () => {
        window.scrollTo(0, 0);
        setCurrentStep(prevStep => {
            if (prevStep < 4) {
                const newStep = prevStep + 1;
                setCurrentColor(getBackgroundColor(newStep));
                return newStep;
            }
            return prevStep;
        });
    };

    const getBackgroundColor = (step: number) => {
        switch (step) {
            case 1: return '#EFECFF';
            case 2: return '#FFEB99';
            case 3: return '#C7E2FB';
            case 4: return '#BBEDD2';
            default: return 'transparent';
        }
    };

    // Dynamically update Step 4 image
    useEffect(() => {
        const updateStep4Image = () => {
            if (window.innerWidth >= 1000 && window.innerHeight < 1600) {
                setStep4Image(<HandsThumbsUp />);
            } else {
                setStep4Image(<HandsHeart className={styles.customHandsStyle}/>);
            }
        };

        updateStep4Image(); // Initial call
        window.addEventListener('resize', updateStep4Image);

        return () => {
            window.removeEventListener('resize', updateStep4Image);
        };
    }, []);

    // Dynamically update Step 3 image
    useEffect(() => {
        const updateStep3Image = () => {
            if (window.innerWidth >= 1000 && window.innerHeight < 1600) {
                setStep3Image(<WavingHand2 />);
            } else {
                setStep3Image(<HandsHiFive className={styles.customHandsStyle}/>);
            }
        };

        updateStep3Image(); // Initial call
        window.addEventListener('resize', updateStep3Image);

        return () => {
            window.removeEventListener('resize', updateStep3Image);
        };
    }, []);

    // Dynamically update Step 2 image
    useEffect(() => {
        const updateStep2Image = () => {
            if (window.innerWidth >= 1000 && window.innerHeight < 1600) {
                setStep2Image(<WavingHand className={styles.step2Image} />);
            } else {
                setStep2Image(
                    <img className={`${styles.welcomeFace} ${styles.step2Image}`} src={WelcomeFace} alt="Smile" />
                );
            }
        };
        updateStep2Image(); // Initial call
        window.addEventListener('resize', updateStep2Image);

        return () => {
            window.removeEventListener('resize', updateStep2Image);
        };
    }, []);

    const steps = [
        {
            step: 1,
            image: <WavingHand  className={styles.step1Image} />,
            title: t('intro.step1.title'),
            text: t('intro.step1.text'),
            buttonText: t('intro.step1.button'),
            titleClassName: styles.title1,
            textClassName: styles.text1,
            imageContainerClassName: '',
        },
        {
            step: 2,
            image: step2Image, // Reference dynamic state
            title: t('intro.step2.title'),
            text: t('intro.step2.text'),
            buttonText: t('intro.step2.button'),
            titleClassName: styles.title2,
            textClassName: styles.text2,
            imageContainerClassName: styles.step2ImageContainer,
        },
        {
            step: 3,
            image: step3Image, // Reference dynamic state
            title: t('intro.step3.title'),
            text: t('intro.step3.text'),
            buttonText: t('intro.step3.button'),
            titleClassName: styles.title3,
            textClassName: styles.text3,
            imageContainerClassName: styles.step3ImageContainer,
        },
        {
            step: 4,
            image: step4Image,
            title: t('intro.step4.title'),
            text: t('intro.step4.text'),
            buttonText: t('intro.step4.button'),
            linkTo: "/profile",
            titleClassName: styles.title4,
            textClassName: styles.text4,
            imageContainerClassName: styles.step4ImageContainer,
        },
    ];

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            {steps.map((stepData) => (
                currentStep === stepData.step && (
                    <StepContent
                        key={stepData.step}
                        {...stepData}
                        handleNext={handleNext}
                        handleBack={currentStep > 1 ? handleBack : undefined}
                        navbarBGColor={getBackgroundColor(currentStep)}
                    />
                )
            ))}
            <Footer backgroundColor={currentColor} />
        </>
    );
};
